import React from 'react'
import Nav from './Nav'


const Header = ({scroll}) => {
  return (
    <header className='Header'>
      <Nav scroll={scroll}/>
      <div className="Header-slider">
        <h1 className='Header-h1'>FB Arquitectos</h1>
        <p className='Header-p'>Diseña construye vive</p>
      </div>

    </header>
  )
}

export default Header