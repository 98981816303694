import React from 'react'

const Contact = () => {
  return (
    <div className='Contact' id="Contact">
       <div className="Contact-title">
        <h3 className="Contact-h3">CONTACTO</h3>
      </div>
      <div className="Contact-container">
          <div className="Contact-info">
            <p className="Contact-p">Por favor, rellene el formulario de esta sección para ponerse en contacto con nosotros.</p>
            <div className="Contact-iconos"><i className="fa-regular fa-envelope Contact-i"></i><span className='Contact-span'>fbautista@gmail.com</span></div>
            <div className="Contact-iconos"><i className="fa-solid fa-location-dot Contact-i"></i><span className='Contact-span'>Av. 16 de Julio #157, Col. Versalles, Puerto Vallarta Jal.</span></div>
            <div className="Contact-iconos"><i className="fa-solid fa-phone Contact-i"></i><span className='Contact-span'>+52 322 185 89 45</span></div>
          </div>
          <div className="Contact-form">
            <form action="">
                <input type="text" placeholder='Name'/>
                <input type="email" placeholder='Email'/>
                <textarea rows="10" cols="20" placeholder='Escribe tu mensaje...'></textarea>
                <input type="submit" className='Contact-btn'/>
            </form>
          </div>
      </div>
    </div>
  )
}

export default Contact