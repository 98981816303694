import React,{useState,useEffect} from 'react'
import About from '../components/About'
import Servicies from '../components/Servicies'
import Header from '../components/Header'
import Portafolio from '../components/Portafolio'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Ourteam from '../components/Ourteam'
import Modal from '../components/Modal'
import SocialMedia from '../components/SocialMedia'

const Home = () => {
  const [modalActive,setModalActive] = useState(false)
  const [portafolioId,setPortafolioId] = useState(0)
  const [scroll,setScroll] = useState(false)
  
  useEffect(() => {
    window.addEventListener("scroll",scrolling);
    return () => {
      window.addEventListener("scroll",scrolling);
    }
  }, [])
  

  const handleModal = (id=0) => {
    setPortafolioId(id)
    setModalActive(!modalActive);
  }


  const scrolling = () => {
    if(window.scrollY > 200){
       return setScroll(true)
    }else{
      return setScroll(false)
    }
  }


  return (
    <div className='Home'>
        <Header scroll={scroll}/>
        <main className='Home-main'>
          <About/>
          <Servicies/>
          <Ourteam/>
          <Portafolio handleModal = {handleModal}/>
          <Contact/>
        </main>
        <Footer/>
        <Modal handleModal = {handleModal} modalActive={modalActive} portafolioId={portafolioId}/>
        <SocialMedia/>
    </div>
  )
}

export default Home