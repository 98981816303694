import React from 'react'

const servicies = () => {
  return (
    <div className='Services' id="Servicies">
      <div className="Services-title">
        <h3 className="Services-h3">SERVICIOS</h3>
      </div>
      
      <div className="Services-container">
        <div className="Services-icono">
          <i className="fa-solid fa-pen-ruler"></i>
          <p className="Services-p">DISEÑO</p>
        </div>
        <div className="Services-icono">
          <i className="fa-solid fa-person-digging"></i>
          <p className="Services-p">ASESORIA</p>
        </div>
        <div className="Services-icono">
          <i className="fa-solid fa-ruler-combined"></i>
          <p className="Services-p">GESTION</p>
        </div>
      </div>
      <div className="Services-container">
        <div className="Services-icono">
          <i className="fa-solid fa-compass-drafting"></i>
          <p className="Services-p">DISEÑO</p>
        </div>
        <div className="Services-icono">
          <i className="fa-solid fa-helmet-safety"></i>
          <p className="Services-p">ASESORIA</p>
        </div>
        <div className="Services-icono">
          <i className="fa-solid fa-ruler-combined"></i>
          <p className="Services-p">GESTION</p>
        </div>
      </div>
    </div>
  )
}

export default servicies