import React from 'react'
import photo from '../media/nosotros.jpg'
const About = () => {
  return (
    <div className='About' id="About">
      <div className="About-img"><img src={photo} alt="" /></div>
      <div className="About-container">
        <div className="About-title">
          <h3 className="About-h3">CONÓCENOS</h3>
        </div>
        <div className="About-text">
          <p className="About-p">
            Somos un estudio de diseño arquitectónico con mas de 8 años de experiencia ofreciendo soluciones integrales a nuestros clientes, con el desarrollo de proyectos ejecutivos innovadores.
          </p>
          <h4 className="About-h4">Visión</h4>
          <p className="About-p">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro excepturi modi distinctio deserunt nisi, beatae neque itaque suscipit veritatis quidem dolorum officiis blanditiis quis accusantium. Rerum sit corporis fuga atque?
          </p>
          <h4 className="About-h4">Misión</h4>
          <p className="About-p">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta unde ipsam mollitia ipsum delectus fugit impedit debitis adipisci explicabo sunt? Distinctio veniam ut adipisci repellendus doloribus quos officiis
          </p>
        </div>
      </div>
    </div>
  )
}

export default About