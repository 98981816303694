import {useEffect,useState} from 'react'
import Home from "./routes/Home";
import DotLoader from 'react-spinners/DotLoader'


function App() {
  const [loanding,setLoandig] = useState(false);
  
  useEffect(() => {
    setLoandig(true);
    
    setTimeout( () => {
      setLoandig(false);
    }, 2000);

  }, [])
  
  return (
    <div className="App">
      {
        loanding ? <div className='Snipper'> <DotLoader color={"rgb(107, 6, 6)"} size={100}/></div> : <Home/>
      }
      
    </div>
  );
}

export default App;
