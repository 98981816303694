import React from 'react'
import dataJson from '../json/portafolio.json'

const Portafolio = ({ handleModal }) => {
  
  return (
    <div className='Portafolio' id="Portafolio">
       <div className="Portafolio-title">
        <h3 className="Portafolio-h3">PORTAFOLIO</h3>
      </div>
      
        
        <div className="Portafolio-container">
          {
            dataJson.map(
              item => {
                return (
                  <div className="Portafolio-card" onClick={() => handleModal(item.id)} key={item.id}>
                    <div className="Portafolio-img" style={{ backgroundImage:`url(${item.img[0].url})`}}></div>
                    <h5 className="Portafolio-h5">{item.title}</h5>
                    <h6 className="Portafolio-h6">{item.ubication}</h6>
                  </div>
                )
              }
            )
          }      
        </div>
      

    </div>
  )
}

export default Portafolio