import React,{useState} from 'react'
import logo from '../media/logo.png'
import {Link} from 'react-scroll'

const Nav = ({scroll}) => {
  const [menu,SetMenu] = useState(false)
  
  const handleMenu = () => {
    SetMenu(!menu) 
  }

  return (
    <nav className={scroll ? 'Nav Nav-white' : "Nav"}>
      <div className="Nav-logo"><img src={logo} alt="" /></div>
      {
        menu ? <div className="Nav-iconMenu Nav-iconMenuWhite" onClick={() => handleMenu()}><i className="fa-solid fa-xmark"></i></div> 
        :
        <div className="Nav-iconMenu" onClick={() => handleMenu()}><i className="fa-solid fa-bars"></i></div> 
      }
      
      <ul className={menu ? 'Nav-ulShow Nav-ul' : 'Nav-ul'}>
        <li className="Nav-li"><Link to="Home" spy={true} smooth={true} offset={0} duration={500} className='Nav-btn' onClick={() => handleMenu()}>Inicio</Link></li>
        <li className="Nav-li"><Link to="About"  spy={true} smooth={true} offset={-50} duration={500} className='Nav-btn' onClick={() => handleMenu()}>Nosotros</Link></li>
        <li className="Nav-li"><Link to="Servicies" spy={true} smooth={true} offset={-40} duration={500} className='Nav-btn' onClick={() => handleMenu()}>Servicios</Link></li>
        <li className="Nav-li"><Link to="Portafolio" spy={true} smooth={true} offset={-50} duration={500} className='Nav-btn' onClick={() => handleMenu()}>Portafolio</Link></li>
        <li className="Nav-li"><Link to="Contact" spy={true} smooth={true} offset={-50} duration={500} className='Nav-btn' onClick={() => handleMenu()}>Contactanos</Link></li>
      </ul>
    </nav>
  )
}

export default Nav