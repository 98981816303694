import React from 'react'

const SocialMedia = () => {
  return (
    <div className='SocialMedia'>
        <a href="" className='SocialMedia-icon'><i className="fa-brands fa-facebook-f"></i></a>
        <i className="fa-brands fa-instagram SocialMedia-icon"></i>
    </div>
  )
}

export default SocialMedia