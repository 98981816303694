import React from 'react'

const Footer = () => {
  return (
    <div className='Footer'>
      <p className='Footer-p'>Copyright © 2020 FBARQUITECTOS</p>
    </div>
  )
}

export default Footer