import React,{useState,useRef,useEffect} from 'react'
import dataJson from '../json/portafolio.json'

const Modal = ( {handleModal,modalActive,portafolioId} ) => {

    const [control,setControl] = useState(0)
    const containerGallery = useRef(null)
    const data = dataJson.filter(item => item.id === portafolioId)    
    
    useEffect(() => {
        setControl(0)
    }, [])
    

    const carrusel = (position) => {
        const porcentaje = position * -100
        containerGallery.current.style.transform =`translate(${porcentaje}%)`;
        setControl(position)
    }

  return (
    <div className={ modalActive === true ? 'Modal' : 'ModalHidden' } >
        <div className="Modal-main" >
            <p className='Modal-btn' onClick={() => handleModal()}>X</p>
            <div className="Modal-container">
                <div className="Modal-info">
                    <h4 className="Modal-h4">{data[0].title}</h4>
                    <p className='Modal-p'>{data[0].info}</p>
                    <div className='Modal-datos'><h5 className="Modal-h5">Ubicación:</h5><span className="Modal-span">{data[0].ubication}</span></div>
                    <div className='Modal-datos'><h5 className="Modal-h5">Fecha:</h5><span className="Modal-span">{data[0].date}</span></div>
                    <div className='Modal-datos'><h5 className="Modal-h5">Construcción:</h5><span className="Modal-span">{data[0].construction}</span></div>
                </div>
                <div className="Modal-galeria">
                    <div className="Modal-galeriaContainer" ref={containerGallery}>
                        {
                            data[0].img.map(
                                item => {
                                    return (
                                        <img src={item.url} alt="" key={item.id} id={item.id}/>
                                    )
                                }
                            )
                        }
                    </div>
                    
                    <div className="Modal-controls">
                        {
                             data[0].img.map(
                                item => {
                                    return (
                                        <div className={control === item.id ? "Modal-control IsActive" :"Modal-control"} onClick={ ()=> carrusel(item.id)} key={item.id}></div>
                                    )
                                }
                            )
                        }
                    </div>
                    
                </div>
            </div>
           
        </div>
    </div>
  )
}

export default Modal