import React,{useRef,useState} from 'react'
import data from '../json/ourTeam.json'

const Ourteam = () => {
  const slideContainer = useRef(null);
  const [control,setControl] = useState(0)

  const carrusell = (position) =>{
      let valor =  -1 * ( position *  slideContainer.current.offsetWidth);
      slideContainer.current.style.transform = `translate(${valor}px)`;
      setControl(position)
  }

  return (
        <section className='OurTeam' id="OurTeam">
            <div className='OurTeam-container'>
            <div className="OurTeam-title">
              <h3 className="OurTeam-h3">NUESTRO EQUIPO</h3>
            </div>

              <div className='OurTeam-Cards'>

                <div className='OurTeam-containerCards' ref={slideContainer}>

                    {
                        data.map(
                          item =>{
                            return(
                                <div className='OurTeam-card' key={item.id}>
                                  <img src={ item.img } alt="" />
                                  <div className='OurTeam-cardInfo'>
                                      <h3>{item.name}</h3>
                                      <h5>{item.role}</h5>
                                  </div>
                              </div>
                            )
                          }
                        )
                    }
                  
                </div>

                <div className='OurTeam-controlCards'>
                  {
                    data.map(
                      item => {
                        return (
                          <div className={ control === item.id ? `OurTeam-control isActive` : `OurTeam-control`} onClick={()=>carrusell(item.id)} key={ item.id }></div>
                        )
                      }
                    )
                  }                  
                </div>

              </div>
            </div>
        </section>
    
  )
}

export default Ourteam